body, html {
    overflow-x: hidden;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    color: #333;
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box !important;
}

#root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}